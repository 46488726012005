import { useCallback } from 'react'
import { ChainId, Currency, Token } from '@meme-dex/sdk'
import styled from 'styled-components'
import {
  Button,
  Text,
  ErrorIcon,
  TransactionCheckmark,
  MetamaskIcon,
  Flex,
  Box,
  Link,
  Spinner,
  Modal,
  InjectedModalProps,
  ModalHeader,
  OpenNewIcon,
  useMatchBreakpoints,
} from 'packages/uikit'
import { registerToken } from 'utils/wallet'
import { useTranslation } from 'contexts/Localization'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { wrappedCurrency } from 'utils/wrappedCurrency'
import { WrappedTokenInfo } from 'state/types'
import { RowFixed } from '../Layout/Row'
import { AutoColumn, ColumnCenter } from '../Layout/Column'
import { getBscScanLink } from '../../utils'

const Wrapper = styled.div`
  width: 100%;
`
const Section = styled(AutoColumn)`
  padding: 0;
`

const ConfirmedIcon = styled(ColumnCenter)`
  padding: 12px 0 24px;
`

const ConfirmTextColor = styled(Text)``

const PositionExploreIcon = styled(Box)`
  position: absolute;
  top: 5px;
  right: 5px;
`

function ConfirmationPendingContent({ pendingText }: { pendingText: string }) {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <ConfirmedIcon>
        <Spinner />
      </ConfirmedIcon>
      <AutoColumn gap="12px" justify="center">
        <ConfirmTextColor color="var(--color-black)" fontSize="14px">
          {t('Waiting For Confirmation')}
        </ConfirmTextColor>
        <AutoColumn gap="12px" justify="center">
          <ConfirmTextColor color="var(--color-black)" bold small textAlign="center">
            {pendingText}
          </ConfirmTextColor>
        </AutoColumn>
        <ConfirmTextColor color="var(--color-black)" fontSize="14px" textAlign="center">
          {t('Confirm this transaction in your wallet')}
        </ConfirmTextColor>
      </AutoColumn>
    </Wrapper>
  )
}

export function TransactionSubmittedContent({
  onDismiss,
  chainId,
  hash,
  currencyToAdd,
  pendingText,
}: {
  onDismiss: () => void
  hash: string | undefined
  chainId: ChainId
  currencyToAdd?: Currency | undefined
  pendingText?: string
}) {
  const { library } = useActiveWeb3React()
  const { t } = useTranslation()
  const token: Token | undefined = wrappedCurrency(currencyToAdd, chainId)
  const { isMobile } = useMatchBreakpoints()

  return (
    <Wrapper>
      <Section>
        <ConfirmedIcon>
          <TransactionCheckmark width={isMobile ? '77px' : '97px'} />
        </ConfirmedIcon>
        <AutoColumn gap="12px" justify="center">
          <ConfirmTextColor>{t('Successful Transaction!')}</ConfirmTextColor>

          <Text fontSize={['14px', , , , '16px']} textAlign="center">
            {pendingText}
          </Text>

          {/* {chainId && hash && (
            <Link
              external
              fontSize="14px"
              style={{ fontStyle: 'italic' }}
              color="var(--color-black)"
              href={getBscScanLink(hash, 'transaction', chainId)}
            >
              {t('View on Explorer')}
            </Link>
          )} */}
          {currencyToAdd && library?.provider?.isMetaMask && (
            <Button
              variant="text"
              width="fit-content"
              height="auto"
              py="0px"
              onClick={() =>
                registerToken(
                  token.address,
                  token.symbol,
                  token.decimals,
                  token instanceof WrappedTokenInfo ? token.logoURI : undefined,
                )
              }
            >
              <RowFixed>
                <Text fontSize={['14px', , , , '16px']} color="var(--color-pink)">
                  {t('Add %asset% to Wallet', { asset: currencyToAdd.symbol })}
                </Text>
                {/* <MetamaskIcon width="22px" ml="6px" /> */}
              </RowFixed>
            </Button>
          )}
          {chainId && hash && (
            <Button
              as={Link}
              href={getBscScanLink(hash, 'transaction', chainId)}
              external
              // onClick={onDismiss}
              style={{ width: '100%', position: 'relative', borderRadius: '10px' }}
            >
              <Text>{t('View on Explore')}</Text>
              <PositionExploreIcon>
                <OpenNewIcon color="black" width="14px" />
              </PositionExploreIcon>
            </Button>
          )}
        </AutoColumn>
      </Section>
    </Wrapper>
  )
}

export function ConfirmationModalContent({
  bottomContent,
  topContent,
}: {
  topContent: () => React.ReactNode
  bottomContent: () => React.ReactNode
}) {
  return (
    <Wrapper>
      <Box>{topContent()}</Box>
      <Box>{bottomContent()}</Box>
    </Wrapper>
  )
}

export function TransactionErrorContent({ message, onDismiss }: { message: string; onDismiss: () => void }) {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <AutoColumn justify="center" gap="md">
        <ErrorIcon color="var(--color-text)" width="64px" />
        <ConfirmTextColor color="failure" style={{ textAlign: 'center', width: '85%', wordBreak: 'break-word' }}>
          {message}
        </ConfirmTextColor>
      </AutoColumn>

      <Flex justifyContent="center" pt="24px">
        <Button width="100%" onClick={onDismiss}>
          {t('Dismiss')}
        </Button>
      </Flex>
    </Wrapper>
  )
}

interface ConfirmationModalProps {
  title: string
  customOnDismiss?: () => void
  hash: string | undefined
  content: () => React.ReactNode
  attemptingTxn: boolean
  pendingText: string
  currencyToAdd?: Currency | undefined
}

const TransactionConfirmationModal: React.FC<InjectedModalProps & ConfirmationModalProps> = ({
  title,
  onDismiss,
  customOnDismiss,
  attemptingTxn,
  hash,
  pendingText,
  content,
  currencyToAdd,
}) => {
  const { chainId } = useActiveWeb3React()

  const handleDismiss = useCallback(() => {
    if (customOnDismiss) {
      customOnDismiss()
    }
    onDismiss?.()
  }, [customOnDismiss, onDismiss])

  if (!chainId) return null

  return (
    <Modal
      textScale="lg"
      title={title}
      headerBackground="gradients.cardHeader"
      onDismiss={handleDismiss}
      bodyPadding={['0 24px 24px']}
      maxWidth="392px"
    >
      {
        // attemptingTxn ? (
        //   <ConfirmationPendingContent pendingText={pendingText} />
        // ) :
        hash ? (
          <TransactionSubmittedContent
            chainId={chainId}
            hash={hash}
            onDismiss={handleDismiss}
            currencyToAdd={currencyToAdd}
            pendingText={pendingText}
          />
        ) : (
          content()
        )
      }
    </Modal>
  )
}

export default TransactionConfirmationModal
