import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 97 98" {...props}>
      <path
        d="M10.5675 98C8.77995 98 7.28052 97.396 6.06921 96.1879C4.86209 94.984 4.25854 93.4906 4.25854 91.7079C4.25854 89.9251 4.86209 88.4318 6.06921 87.2279C7.28052 86.0198 8.77995 85.4157 10.5675 85.4157H86.2748C88.0623 85.4157 89.5596 86.0198 90.7668 87.2279C91.9781 88.4318 92.5837 89.9251 92.5837 91.7079C92.5837 93.4906 91.9781 94.984 90.7668 96.1879C89.5596 97.396 88.0623 98 86.2748 98H10.5675ZM32.9642 70.1573C32.123 70.1573 31.3344 70.0252 30.5984 69.7609C29.8623 69.5008 29.1789 69.0562 28.548 68.427L1.73496 41.6854C0.57832 40.5318 0 39.0112 0 37.1236C0 35.236 0.57832 33.7154 1.73496 32.5618C2.8916 31.4082 4.36369 30.8315 6.15122 30.8315C7.93876 30.8315 9.41084 31.4082 10.5675 32.5618L32.9642 54.8989L86.2748 1.73033C87.4314 0.576777 88.9309 0 90.7731 0C92.6111 0 94.1084 0.576777 95.265 1.73033C96.4217 2.88389 97 4.37723 97 6.21034C97 8.04764 96.4217 9.54307 95.265 10.6966L37.3805 68.427C36.7496 69.0562 36.0661 69.5008 35.3301 69.7609C34.594 70.0252 33.8054 70.1573 32.9642 70.1573Z"
        fill="#39DD8C"
      />
    </Svg>
  )
}

export default Icon
